import React from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import MainHeader from "../../components/Header/MainHeader";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addLoanAmount } from "../../redux/slices/registerSlice";
import { enterLoanAmountValidation } from "../../validations/user.validation";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";

export default function LoanAmount() {
  const { loanAmount } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);

  // console.log("loanAmount------------", loanAmount);
  // console.log("repeatCustomer------------", repeatCustomer);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { incompleteDetailsUpdate } = new ServicesAPI();
  const { handleStep } = useResponse();

  return (
    <div className="wrapper">
      <Formik
        enableReinitialize
        initialValues={{ loanAmount: loanAmount || "" }}
        validationSchema={enterLoanAmountValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          setTimeout(async () => {
            if (repeatCustomer) {
              dispatch(addLoanAmount(values.loanAmount));
              window.location.href = "/confirm-details";
              localStorage.setItem("loanAmount", values.loanAmount);
            } else {
              const res = await incompleteDetailsUpdate({ key: "loanRequeried", value: values.loanAmount });
              if (res) {
                dispatch(addLoanAmount(values.loanAmount));
                handleStep();
              }
            }

            WebEngageTrackingEvent("Loan Amount Submitted", {
              "Loan Amount": parseInt(values.loanAmount),
              customer_type: localStorage.getItem("customerType"),
              app_version: localStorage.getItem("appVersion"),
            });

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <MainHeader
                  title="Loan Amount"
                  showProgress={true}
                  percent={25}
                  backPageName={repeatCustomer ? "/dashboard" : ""}
                />

                <div className="mb-3">
                  <p className="label">Enter loan amount</p>
                </div>

                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Enter loan amount"
                    name="loanAmount"
                    value={values.loanAmount}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d*$/.test(input)) {
                        setFieldValue("loanAmount", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue("loanAmount", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.loanAmount && errors.loanAmount ? (
                    <div className="error-msg">{errors.loanAmount}</div>
                  ) : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />

                <CustomButton text="Next" type="submit" />
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
