import React, { useState } from "react";
import styles from "./ReferenceDetails.module.scss";
import { Formik } from "formik";

// components
import MainHeader from "../../components/Header/MainHeader";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import { referenceDetailsValidation } from "../../validations/user.validation";
import ReferenceModal from "../../components/Modal/ReferenceModal";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";

export default function ReferenceDetails() {
  const [loading, setLoading] = useState(false);
  const [referenceOne, setReferenceOne] = useState(true);
  const [referenceTwo, setReferenceTwo] = useState(false);
  const [openReferenceModal, setOpenReferenceModal] = useState({ bottom: false });

  const { handleStep } = useResponse();

  const { addReferenceAPI } = new AfterOnboardAPI();

  const submitReferenceDetails = async (values) => {
    setLoading(true);
    const res = await addReferenceAPI(values);

    if (res) {
      handleStep();
    }
    setLoading(false);
    setOpenReferenceModal({ ...openReferenceModal, bottom: false });
  };

  const initialValues = {
    nameOne: "",
    relationOne: "",
    mobileOne: "",
    nameTwo: "",
    relationTwo: "",
    mobileTwo: "",
  };

  return (
    <div className="wrapper auto-height">
      <MainHeader title="Reference Details" showProgress={true} percent={62} />
      <p className="label">Reference Details</p>
      <p className="sub-label mb-3" style={{ marginTop: -5 }}>
        Please Provide 2 Reference for your loan.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={referenceDetailsValidation}
        onSubmit={(values, { setSubmitting }) => {
          // console.log("values----------", values);

          WebEngageTrackingEvent("Reference Details Submitted", {
            "Reference 1 Name": values.nameOne,
            "Reference 1 Relation": values.relationOne,
            "Reference 1 Phone Number": values.mobileOne,
            "Reference 2 Name": values.nameTwo,
            "Reference 2 Relation": values.relationTwo,
            "Reference 2 Phone Number": values.mobileTwo,
            customer_type: localStorage.getItem("customerType"),
            app_version: localStorage.getItem("appVersion"),
          });

          setLoading(true);

          setTimeout(async () => {
            setOpenReferenceModal({ ...openReferenceModal, bottom: true });
            setLoading(false);
          }, 500);
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(errors) => {
          if (Object.keys(errors).length > 0) {
            setReferenceOne(true);
            setReferenceTwo(true);
          }
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.referenceCard}>
              <div className={styles.refHeader} onClick={() => setReferenceOne(!referenceOne)}>
                <span className={`${styles.radioButton} ${referenceOne ? styles.active : ""}`}></span>{" "}
                <h3>Reference 1</h3>
              </div>

              {referenceOne && (
                <>
                  <div className="form-group mt-1">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Referred Name"
                      name="nameOne"
                      value={values.nameOne}
                      onChange={handleChange}
                      onBlur={(e) => {
                        setFieldValue("nameOne", e.target.value.trim());
                      }}
                      autoComplete="off"
                    />

                    {touched.nameOne && errors.nameOne ? <div className="error-msg">{errors.nameOne}</div> : null}
                  </div>

                  <div className="form-group mt-1">
                    <label>Relation</label>
                    <select
                      className="form-select"
                      name="relationOne"
                      value={values.relationOne}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Relation</option>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Relative">Relative</option>
                      <option value="Friends">Friends</option>
                      <option value="Other">Other</option>
                    </select>

                    {touched.relationOne && errors.relationOne ? (
                      <div className="error-msg">{errors.relationOne}</div>
                    ) : null}
                  </div>

                  <div className="form-group mb-0">
                    <label>Mobile Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      name="mobileOne"
                      value={values.mobileOne}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (/^\d*$/.test(input)) {
                          setFieldValue("mobileOne", e.target.value);
                        }
                      }}
                      onBlur={(e) => {
                        setFieldValue("mobileOne", e.target.value.trim());
                      }}
                      autoComplete="off"
                      maxLength={10}
                    />

                    {touched.mobileOne && errors.mobileOne ? <div className="error-msg">{errors.mobileOne}</div> : null}
                  </div>
                </>
              )}
            </div>

            <div className={styles.referenceCard}>
              <div className={styles.refHeader} onClick={() => setReferenceTwo(!referenceTwo)}>
                <span className={`${styles.radioButton} ${referenceTwo ? styles.active : ""}`}></span>{" "}
                <h3>Reference 2</h3>
              </div>

              {referenceTwo && (
                <>
                  <div className="form-group mt-1">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Referred Name"
                      name="nameTwo"
                      value={values.nameTwo}
                      onChange={handleChange}
                      onBlur={(e) => {
                        setFieldValue("nameTwo", e.target.value.trim());
                      }}
                      autoComplete="off"
                    />

                    {touched.nameTwo && errors.nameTwo ? <div className="error-msg">{errors.nameTwo}</div> : null}
                  </div>

                  <div className="form-group mt-1">
                    <label>Relation</label>
                    <select
                      className="form-select"
                      name="relationTwo"
                      value={values.relationTwo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="">Select Relation</option>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Relative">Relative</option>
                      <option value="Friends">Friends</option>
                      <option value="Other">Other</option>
                    </select>

                    {touched.relationTwo && errors.relationTwo ? (
                      <div className="error-msg">{errors.relationTwo}</div>
                    ) : null}
                  </div>

                  <div className="form-group mb-0">
                    <label>Mobile Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      name="mobileTwo"
                      value={values.mobileTwo}
                      onChange={(e) => {
                        const input = e.target.value;
                        if (/^\d*$/.test(input)) {
                          setFieldValue("mobileTwo", e.target.value);
                        }
                      }}
                      onBlur={(e) => {
                        setFieldValue("mobileTwo", e.target.value.trim());
                      }}
                      autoComplete="off"
                      maxLength={10}
                    />

                    {touched.mobileTwo && errors.mobileTwo ? <div className="error-msg">{errors.mobileTwo}</div> : null}
                  </div>
                </>
              )}
            </div>

            <div className="mt-4 mb-1">
              <DataSafetyMessage />

              <CustomButton text="Next" type="submit" />
            </div>

            <ReferenceModal
              openReferenceModal={openReferenceModal}
              setOpenReferenceModal={setOpenReferenceModal}
              values={values}
              submitReferenceDetails={submitReferenceDetails}
            />

            {loading && <Loader />}
          </form>
        )}
      </Formik>

      <br />
    </div>
  );
}
