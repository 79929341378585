// Get Environment

const ENV_CONFIG = {
  dev: {
    API_URL: process.env.REACT_APP_API_URL_DEV,
    PAYMENT_URL: process.env.REACT_APP_API_PAYMENT_URL_DEV,
    PRODUCT: process.env.REACT_APP_PRODUCT,
  },
  staging: {
    API_URL: process.env.REACT_APP_API_URL_STAGING,
    PAYMENT_URL: process.env.REACT_APP_API_PAYMENT_URL_STAGING,
    PRODUCT: process.env.REACT_APP_PRODUCT,
  },
  production: {
    API_URL: process.env.REACT_APP_API_URL_PROD,
    PAYMENT_URL: process.env.REACT_APP_API_PAYMENT_URL_PROD,
    PRODUCT: process.env.REACT_APP_PRODUCT,
  },
};

const getEnvironment = () => {
  const server = process.env.REACT_APP_SERVER;

  if (!server) {
    throw new Error("Environment variables is not defined!");
  }

  return ENV_CONFIG[server];
};

export { getEnvironment };
