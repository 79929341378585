import React from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import MainHeader from "../../components/Header/MainHeader";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { monthlyIncomeValidation } from "../../validations/user.validation";
import { addMonthlyIncome } from "../../redux/slices/registerSlice";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";

export default function MonthlyIncome() {
  const { monthlyIncome } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { incompleteDetailsUpdate } = new ServicesAPI();
  const { handleStep } = useResponse();

  return (
    <div className="wrapper">
      <Formik
        initialValues={{ monthlyIncome: monthlyIncome || "" }}
        validationSchema={monthlyIncomeValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          WebEngageTrackingEvent("Monthly Income Submitted", {
            customer_type: localStorage.getItem("customerType"),
            app_version: localStorage.getItem("appVersion"),
          });

          setTimeout(async () => {
            if (repeatCustomer) {
              dispatch(addMonthlyIncome(values.monthlyIncome));
              navigate("/confirm-details");
            } else {
              const res = await incompleteDetailsUpdate({ key: "monthlyIncome", value: values.monthlyIncome });
              if (res && res.statusCode === 200) {
                dispatch(addMonthlyIncome());
                handleStep();
              }
            }

            WebEngageUserAttributes("Monthly Income", parseInt(values.monthlyIncome));

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <MainHeader
                  title="Professional Details"
                  showProgress={true}
                  percent={69.21}
                  backPageName={repeatCustomer ? "/confirm-details" : ""}
                />
                <div className="mb-3">
                  <p className="label">Monthly Income</p>
                </div>

                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Enter your monthly income"
                    name="monthlyIncome"
                    value={values.monthlyIncome}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input.length <= 10 && /^\d*$/.test(input)) {
                        setFieldValue("monthlyIncome", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue("monthlyIncome", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.monthlyIncome && errors.monthlyIncome ? (
                    <div className="error-msg">{errors.monthlyIncome}</div>
                  ) : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />
                <CustomButton text="Next" type="submit" />
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
