import React, { useEffect, useState } from "react";
import styles from "./LoanApproval.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowBack, IoIosArrowRoundForward } from "react-icons/io";

// components
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import FinboxAPI from "../../services/finbox.api";
import { addUpgradeLoanAmount, changeLoanApproved } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function LoanApproval() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const { accountId } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { loanApprovedAPI } = new AfterOnboardAPI();
  const { finboxcreateUrlAPI } = new FinboxAPI();
  const { handleStep } = useResponse();

  useEffect(() => {
    dispatch(changeLoanApproved(true));
    handleFetchAPI();
  }, []);

  const handleFetchAPI = async () => {
    const res = await loanApprovedAPI();
    if (res && res.statusCode === 200) {
      setData(res.data);

      WebEngageTrackingEvent("Loan Amount Approved", {
        "Approved Amount": parseInt(res.data.totalAmount),
        "Processing Fee": parseInt(res.data.processingAmount),
        "Net Disbursal amount": parseInt(res.data.finalAmount),
        "Repayment Date": new Date(res.data.repayDate),
        "Repayment Amount": parseInt(res.data.repayAmount),
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
    }
    setLoading(false);
  };

  const handleUpgradeLoanAmount = async () => {
    setLoading(true);
    const res = await finboxcreateUrlAPI();
    if (res) {
      dispatch(addUpgradeLoanAmount());
      window.location.href = res.data.redirect_url;

      WebEngageTrackingEvent("Upgrade Amount", {
        "Approved Amount": parseInt(data.totalAmount),
        "Processing Fee": parseInt(data.processingAmount),
        "Net Disbursal amount": parseInt(data.finalAmount),
        "Repayment Date": new Date(data.repayDate),
        "Repayment Amount": parseInt(data.repayAmount),
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className={styles.loanApprovalStyle}>
      <div>
        {/* Top Header */}
        <div className={styles.topHeader}>
          <div className={styles.backButton} onClick={() => (window.location.href = "/dashboard")}>
            <IoIosArrowBack size={34} color="#ffffff" />
          </div>
          <div className={styles.message}>
            <p className={styles.firstText}>
              <span className="fw-600">Special Offer!</span> Only For You
            </p>
            {/* <p className={`${styles.secondText} fw-600`}>
              Ends in: <span className="ml-1">2h:25m:6s</span>
            </p> */}
          </div>
          <div className={styles.bgImage} />
        </div>

        {/* Loan Approved */}
        <div className={styles.loanApproved}>
          <h3>Loan Approved</h3>
          <div className={styles.images}>
            <img src="/images/hand-holding.svg" />
          </div>
          <p>You have been approved for a loan of amount</p>
          <h2>₹{data?.totalAmount}</h2>

          <div className={styles.amountDetails}>
            <div>Approved amount</div>
            <div className="fw-700">₹{data?.totalAmount}</div>
          </div>
          <div className={styles.amountDetails}>
            <div>Processing fee +GST</div>
            <div className="fw-600">-₹{data?.processingAmount + data?.gstOfAdminFee}</div>
          </div>
          <div className={`${styles.amountDetails} ${styles.finalAmount}`}>
            <div>Final loan amount</div>
            <div className="fw-600">₹{data?.finalAmount}</div>
          </div>

          {data?.isLoanAmountUpgradable && (
            <div className={styles.upgradeAmount} onClick={() => handleUpgradeLoanAmount()}>
              Upgrade my Loan Amount
            </div>
          )}
        </div>

        {/* Repayment Plan */}
        <div className={styles.repaymentPlan}>
          <h4>Repayment plan</h4>

          <div className={styles.repaymentBlock}>
            <div className={styles.left}>
              <div className={styles.title}>Repayment Date</div>
              <div className={styles.value}>
                {new Date(data?.repayDate).toDateString()} <span className={styles.days}>{data?.tenure} days</span>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.title}>Repayment Amount</div>
              <div className={styles.value}>₹{data?.repayAmount}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Total Amount */}
      <div className={styles.totalAmount}>
        <div>
          <p>Total</p>
          <h5>₹{data?.totalAmount}</h5>
          <span className={styles.taxText}>Inclusive of all taxes</span>
        </div>
        <div>
          <button
            onClick={() => {
              handleStep(accountId);
              WebEngageTrackingEvent("Continue Loan Amount", {
                "Approved Amount": parseInt(data.totalAmount),
                "Processing Fee": parseInt(data.processingAmount),
                "Net Disbursal amount": parseInt(data.finalAmount),
                "Repayment Date": new Date(data.repayDate),
                "Repayment Amount": parseInt(data.repayAmount),
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          >
            <span>Continue</span> <IoIosArrowRoundForward size={32} />
          </button>
        </div>
      </div>
    </div>
  );
}
