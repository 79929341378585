import React, { useState } from "react";
import styles from "./EMICalendar.module.scss";
import { IoIosArrowDown, IoIosArrowRoundForward } from "react-icons/io";
import { CustomButton } from "../../../../components";

export default function EMICalendar(props) {
  const [emi, setEMI] = useState([
    {
      date: "4 May, 2024",
      status: "paid",
      amount: "1200",
      principal: "200",
      interest: "200",
      gst: "200",
      show: false,
    },
    {
      date: "4 Jun, 2024",
      status: "paid",
      amount: "1200",
      principal: "200",
      interest: "200",
      gst: "200",
      show: false,
    },
    {
      date: "4 Jul, 2024",
      status: "paid",
      amount: "1200",
      principal: "200",
      interest: "200",
      gst: "200",
      show: false,
    },
    {
      date: "4 Aug, 2024",
      status: "paid",
      amount: "1200",
      principal: "200",
      interest: "200",
      gst: "200",
      show: false,
    },
    {
      date: "4 Sep, 2024",
      status: "paid",
      amount: "1200",
      principal: "200",
      interest: "200",
      gst: "200",
      show: false,
    },
    {
      date: "4 Oct, 2024",
      status: "paid",
      amount: "1200",
      principal: "200",
      interest: "200",
      gst: "200",
      show: false,
    },
  ]);

  return (
    <div className={styles.emiCalendar}>
      {emi.map((item, index) => (
        <div className={styles.emiContainer} key={index}>
          <div
            className={styles.emiList}
            onClick={() =>
              setEMI((prevData) => prevData.map((item, i) => (i === index ? { ...item, show: !item.show } : item)))
            }
          >
            <div className={styles.info}>
              <div className={styles.number}>{index + 1}</div>
              <div className={styles.date}>{item.date}</div>
              <div className={styles.status}>Paid</div>
            </div>
            <div className={styles.price}>
              <span>₹ 1,200</span>
              <IoIosArrowDown size={15} color="#000000" />
            </div>
          </div>

          {item.show && (
            <div>
              <div className={styles.emiDetails}>
                <div className={styles.summary}>Principle</div>
                <div className={styles.emiPrice}>₹ {item.principal}</div>
              </div>
              <div className={styles.emiDetails}>
                <div className={styles.summary}>Interest</div>
                <div className={styles.emiPrice}>₹ {item.interest}</div>
              </div>
              <div className={styles.emiDetails}>
                <div className={styles.summary}>GST</div>
                <div className={styles.emiPrice}>₹ {item.gst}</div>
              </div>
            </div>
          )}
        </div>
      ))}

      {/* Total Amount */}
      <div className={styles.totalAmount}>
        <div>
          <p>Total</p>
          <h5>₹1200.00</h5>
          <span className={styles.taxText}>Inclusive of all taxes</span>
        </div>
        <div>
          <CustomButton text="Pay Now" endIcon={<IoIosArrowRoundForward size={30} />} />
        </div>
      </div>
    </div>
  );
}
