import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import MainHeader from "../../components/Header/MainHeader";
import SelectOption from "../../components/Select/SelectOption";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addLoanPurpose } from "../../redux/slices/registerSlice";
import { storeLeadID } from "../../utils/storage";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function LoanPurpose() {
  const [loading, setLoading] = useState(false);

  const { loanPurpose } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleStep } = useResponse();

  const { incompleteDetailsUpdate } = new ServicesAPI();

  const data = ["Medical Emergency", "Salary Delay", "Personal Emergency", "Education", "Rent / Bills", "Others"];

  const handleIncompleteDetailsUpdateAPI = async (data) => {
    setLoading(true);

    if (repeatCustomer) {
      dispatch(addLoanPurpose(data.value));
      navigate("/confirm-details");
    } else {
      const res = await incompleteDetailsUpdate(data);

      if (res) {
        storeLeadID(res?.data?.leadID);
        dispatch(addLoanPurpose(data.value));
        handleStep();
      }
    }

    WebEngageTrackingEvent("Loan Purpose Submitted", {
      Purpose: data.value,
      customer_type: localStorage.getItem("customerType"),
      app_version: localStorage.getItem("appVersion"),
    });

    setLoading(false);
  };

  return (
    <>
      <div className="wrapper auto-height">
        <MainHeader
          title="Loan Purpose"
          showProgress={true}
          percent={7.69}
          backPageName={repeatCustomer ? "/confirm-details" : ""}
        />
        <div className="mb-3">
          <p className="label">Loan Purpose</p>
        </div>

        {data.map((item, index) => (
          <div
            key={index}
            onClick={async () => {
              handleIncompleteDetailsUpdateAPI({
                key: "purposeloan",
                value: item,
              });
            }}
          >
            <SelectOption name={item} checked={loanPurpose} />
          </div>
        ))}

        <br />
      </div>

      {loading && <Loader />}
    </>
  );
}
