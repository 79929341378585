const storeToken = (token) => {
  try {
    localStorage.setItem("token", token);
  } catch (error) {
    console.log("Error storing in token", error);
  }
};

const getToken = () => {
  try {
    return localStorage.getItem("token");
  } catch (error) {
    console.log("Error getting in token", error``);
  }
};

const storeLeadID = (leadID) => {
  try {
    localStorage.setItem("leadID", leadID);
  } catch (error) {
    console.log("Error storing in leadID", error);
  }
};

const getLeadID = () => {
  try {
    return Number(localStorage.getItem("leadID"));
  } catch (error) {
    console.log("Error getting in leadID", error);
  }
};

export { storeToken, getToken, storeLeadID, getLeadID };
