import React from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// components
import MainHeader from "../../components/Header/MainHeader";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addCompanyName } from "../../redux/slices/registerSlice";
import { enterCompanyNameValidation } from "../../validations/user.validation";
import { useNavigate } from "react-router-dom";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";

export default function CompanyName() {
  const { companyName } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { incompleteDetailsUpdate } = new ServicesAPI();
  const { handleStep } = useResponse();

  // console.log("repeatCustomer---------", repeatCustomer);

  return (
    <div className="wrapper">
      <Formik
        initialValues={{ companyName: companyName || "" }}
        validationSchema={enterCompanyNameValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          WebEngageTrackingEvent("Company Name Submitted", {
            customer_type: localStorage.getItem("customerType"),
            app_version: localStorage.getItem("appVersion"),
          });

          setTimeout(async () => {
            const res = await incompleteDetailsUpdate({
              key: "companyName",
              value: values.companyName,
            });

            if (res && res.statusCode === 200) {
              dispatch(addCompanyName(values.companyName));
              if (repeatCustomer) {
                navigate("/confirm-details");
                // window.location.href = "/confirm-details";
              } else {
                handleStep();
              }

              WebEngageUserAttributes("Company", values.companyName);
            }

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <MainHeader
                  title="Employment Details"
                  showProgress={true}
                  percent={46.14}
                  backPageName={repeatCustomer ? "/confirm-details" : ""}
                />

                <div className="mb-3">
                  <p className="label">Enter your Company's name</p>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Company's name"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("companyName", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.companyName && errors.companyName ? (
                    <div className="error-msg">{errors.companyName}</div>
                  ) : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />
                <CustomButton text="Next" type="submit" />
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
