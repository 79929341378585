import React, { useEffect, useState } from "react";
import styles from "./PanNotLinked.module.scss";

// libraries
import Swal from "sweetalert2";

// components
import Loader from "../../components/Loader/Loader";
import MainHeader from "../../components/Header/MainHeader";
import CustomButton from "../../components/Button/CustomButton";
import ServicesAPI from "../../services/services";
import useResponse from "../../hooks/useResponse";

export default function PanNotLinked() {
  // const [loading, setLoading] = useState("");
  const [data, setData] = useState(null);

  const { rekycButtonMessages, panAadhaarMismatchClick, aadhaarPanReverify } = new ServicesAPI();
  const { handleStep } = useResponse();

  useEffect(() => {
    fetchAPI();
  }, []);

  const fetchAPI = async () => {
    const res = await rekycButtonMessages();

    if (res && res.statusCode === 200) {
      setData(res.data);
    }
  };

  const handlePanAadhaarMismatch = async () => {
    const res = await panAadhaarMismatchClick();

    if (res && res.statusCode === 200) {
      window.location.href = "https://www.incometax.gov.in/iec/foportal/help/how-to-link-aadhaar";
    }
  };

  const handleAadhaarPanReverify = async () => {
    const res = await aadhaarPanReverify();

    if (res && res.statusCode === 200) {
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: "Verified Successfully",
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000,
      });
      setTimeout(() => {
        handleStep();
      }, 2000);
    } else if (res.statusCode === 400) {
      Swal.fire({
        text: res.message,
        icon: "error",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = res.data.route;
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res.message,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  };

  // console.log(data);

  if (!data) {
    return <Loader />;
  }
  return (
    <>
      <div className="wrapper">
        <div className={`vertical-column-layout ${styles.pan__aadhaarVerify}`}>
          <div className="vertical-column-top">
            <MainHeader title="" />

            <div className={styles.aadhaarImage}>
              <img src="/images/comdined.webp" alt="" />
              <h3>{data?.msg1}</h3>
              <p>{data?.msg2}</p>
            </div>
          </div>

          <div className="vertical-column-bottom">
            {data.isLinkedDone !== "0" && (
              <CustomButton text="My Aadhaar & PAN is Linked" variant="outline" onClick={handleAadhaarPanReverify} />
            )}
            <br />
            <CustomButton text="Link my Aadhaar & PAN" variant="outline" onClick={handlePanAadhaarMismatch} />
          </div>
        </div>
      </div>
    </>
  );
}
