import React from "react";
import "./Modal.scss";
import Drawer from "@mui/material/Drawer";
import { FaEdit } from "react-icons/fa";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import CustomButton from "../Button/CustomButton";

export default function ReferenceModal(props) {
  const { openReferenceModal, setOpenReferenceModal, values, submitReferenceDetails } = props;

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={openReferenceModal["bottom"]}
        onClose={() => setOpenReferenceModal({ ...openReferenceModal, bottom: false })}
      >
        <div className="reference__modal">
          <h3>Are these references correct?</h3>
          <div className="reference-list">
            <div className="title">
              <h6>Reference 1</h6>
              <FaEdit
                size={18}
                color="#939393"
                onClick={() => setOpenReferenceModal({ ...openReferenceModal, bottom: false })}
              />
            </div>
            <p>{values.nameOne}</p>
            <p>+91 {values.mobileOne}</p>
          </div>

          <div className="reference-list">
            <div className="title">
              <h6>Reference 2</h6>
              <FaEdit
                size={18}
                color="#939393"
                onClick={() => setOpenReferenceModal({ ...openReferenceModal, bottom: false })}
              />
            </div>
            <p>{values.nameTwo}</p>
            <p>+91 {values.mobileTwo}</p>
          </div>

          <p>
            <strong>Note:</strong> Your provided references may be reached out to verify details in relation to your
            loan application.
          </p>

          <br />

          <CustomButton
            text="Yes, these are correct"
            onClick={() => {
              submitReferenceDetails(values);

              WebEngageTrackingEvent("Reference Details Confirmed", {
                "Reference 1 Name": values.nameOne,
                "Reference 1 Relation": values.relationOne,
                "Reference 1 Phone Number": values.mobileOne,
                "Reference 2 Name": values.nameTwo,
                "Reference 2 Relation": values.relationTwo,
                "Reference 2 Phone Number": values.mobileTwo,
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          />

          {/* <button
            className="button"
            onClick={() => {
              submitReferenceDetails(values);

              WebEngageTrackingEvent("Reference Details Confirmed", {
                "Reference 1 Name": values.nameOne,
                "Reference 1 Relation": values.relationOne,
                "Reference 1 Phone Number": values.mobileOne,
                "Reference 2 Name": values.nameTwo,
                "Reference 2 Relation": values.relationTwo,
                "Reference 2 Phone Number": values.mobileTwo,
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          >
            Yes, these are correct <i className="shine"></i>
          </button> */}
        </div>
      </Drawer>
    </div>
  );
}
