import React from "react";
import styles from "./LoanRepayment.module.scss";
import CustomButton from "../Button/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { getEnvironment } from "../../utils/environment";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function LoanRepayment({ data }) {
  const navigate = useNavigate();

  const { PAYMENT_URL } = getEnvironment();

  return (
    <div className={styles.loanRepayment}>
      <div className="text-center">
        <div className={`${styles.message} ${data.isLoanOverDue ? styles.overdueMessage : ""}`}>
          <span>{data.dashboard_message1}</span>
        </div>
      </div>

      <div>
        <p className={styles.title}>{data.dashboard_message2}</p>
        <div className={styles.paymentAmount}>
          <h2>₹{data.dashboard_message6}</h2>
          {data.isLoanOverDue && <div className={styles.overdueDate}>{data.dashboard_message5}</div>}
        </div>

        {data.isLoanOverDue ? (
          <p className={styles.greetMessage}>{data.dashboard_message7}</p>
        ) : (
          <p className={styles.greetMessage}>{data.dashboard_message5}</p>
        )}

        <div className={styles.dueDate}>
          <div className={styles.dateLabel}>Due Date</div>
          <div className={data.isLoanOverDue ? styles.dateValueOverdue : styles.dateValue}>
            {data.dashboard_message4}
          </div>
        </div>

        <div className={styles.button}>
          <CustomButton
            text={data?.dashboard_message3}
            endIcon={<FaArrowRightLong />}
            onClick={() => {
              if (data?.dashboard_message3 === "Repay Loan") {
                window.location.href = PAYMENT_URL;
              } else {
                navigate(data.step.current_route);
              }

              WebEngageTrackingEvent("Repay Initiated", {
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
            size="lg"
            disabled={!data.buttonEnable}
          />
        </div>
      </div>
    </div>
  );
}
