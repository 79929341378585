import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import FinboxAPI from "../../services/finbox.api";

export default function FinboxCreate() {
  const { finboxcreateUrlAPI } = new FinboxAPI();

  useEffect(() => {
    generateFinboxURL();
  }, []);

  const generateFinboxURL = async () => {
    const res = await finboxcreateUrlAPI();
    if (res && res.statusCode === 200) {
      window.location.href = res.data.redirect_url;
    }
  };

  return (
    <div>
      <Loader />
    </div>
  );
}
