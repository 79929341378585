import React from "react";
import styles from "./Transactions.module.scss";
import { FaCircleCheck } from "react-icons/fa6";
import { IoMdAlert } from "react-icons/io";

export default function Transactions(props) {
  return (
    <div className={styles.transactions}>
      <div className={styles.list}>
        <div className={styles.block}>
          <div className={styles.amount}>Amount</div>
          <div className={styles.amount}>₹100.00</div>
        </div>
        <div className={styles.block}>
          <div>Status</div>
          <div className={styles.success}>
            <FaCircleCheck size={14} color="#14d44a" />
            <span>Success</span>
          </div>
        </div>
        <div className={styles.block}>
          <div>Date & Time</div>
          <div>10th Jun 2024, 11:45 am</div>
        </div>
        <div className={styles.block}>
          <div>Transaction ID</div>
          <div>252363562DFt25555555</div>
        </div>
        <div className={styles.block}>
          <div>Payment mode</div>
          <div>UPI</div>
        </div>
      </div>

      <div className={styles.list}>
        <div className={styles.block}>
          <div className={styles.amount}>Amount</div>
          <div className={styles.amount}>₹100.00</div>
        </div>
        <div className={styles.block}>
          <div>Status</div>
          <div className={styles.success}>
            <FaCircleCheck size={14} color="#14d44a" />
            <span>Success</span>
          </div>
        </div>
        <div className={styles.block}>
          <div>Date & Time</div>
          <div>10th Jun 2024, 11:45 am</div>
        </div>
        <div className={styles.block}>
          <div>Transaction ID</div>
          <div>252363562DFt25555555</div>
        </div>
        <div className={styles.block}>
          <div>Payment mode</div>
          <div>UPI</div>
        </div>
      </div>

      <div className={styles.list}>
        <div className={styles.block}>
          <div className={styles.amount}>Amount</div>
          <div className={styles.amount}>₹100.00</div>
        </div>
        <div className={styles.block}>
          <div>Status</div>
          <div className={styles.success}>
            <IoMdAlert size={16} color="#d93c3c" />
            <span style={{ color: "#d93c3c" }}>Failed</span>
          </div>
        </div>
        <div className={styles.block}>
          <div>Date & Time</div>
          <div>10th Jun 2024, 11:45 am</div>
        </div>
        <div className={styles.block}>
          <div>Transaction ID</div>
          <div>252363562DFt25555555</div>
        </div>
        <div className={styles.block}>
          <div>Payment mode</div>
          <div>UPI</div>
        </div>
      </div>

      <div className={styles.list}>
        <div className={styles.block}>
          <div className={styles.amount}>Amount</div>
          <div className={styles.amount}>₹100.00</div>
        </div>
        <div className={styles.block}>
          <div>Status</div>
          <div className={styles.success}>
            <IoMdAlert size={16} color="#eb8503" />
            <span style={{ color: "#eb8503" }}>Pending</span>
          </div>
        </div>
        <div className={styles.block}>
          <div>Date & Time</div>
          <div>10th Jun 2024, 11:45 am</div>
        </div>
        <div className={styles.block}>
          <div>Transaction ID</div>
          <div>252363562DFt25555555</div>
        </div>
        <div className={styles.block}>
          <div>Payment mode</div>
          <div>UPI</div>
        </div>
      </div>
    </div>
  );
}
