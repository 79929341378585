import api from "../utils/api";
import { serviceError } from "../lib/serviceError";
import { getLeadID } from "../utils/storage";

class FinboxAPI {
  // Finbox Bank Connect API
  async finboxBankConnectAPI(entityId) {
    try {
      const endpoint = "new-api/customer_onboarding/finbox-bank-connect";
      const req = {
        entityId: entityId,
        leadID: Number(localStorage.getItem("leadID")),
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Finbox Create URL
  async finboxcreateUrlAPI() {
    const host = window.location.href.split("/");
    try {
      const endpoint = "new-api/customer_onboarding/finbox-create-url";
      const req = {
        leadID: getLeadID(),
        callBackUrl: host[0] + "//" + host[2] + "/finbox",
      };
      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }

  // Banking Surrogate
  async bankingSurrogateAPI() {
    try {
      const endpoint = "new-api/customer_onboarding/banking-surrogate";
      const req = {
        leadId: getLeadID(),
      };
      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      serviceError(error);
    }
  }
}

export default FinboxAPI;
