import React from "react";
import styles from "./GetCash.module.scss";
import CustomButton from "../Button/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useResponse from "../../hooks/useResponse";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function GetCash({ data }) {
  const navigate = useNavigate();

  const { handleStep } = useResponse();

  // console.log("status-----------", data);

  return (
    <>
      <div className={styles.get__cash}>
        <div className={styles.top__message}>{data?.dashboard_message1}</div>

        <p>{data?.dashboard_message2}</p>
        <img src={data?.imageUrl} className={styles.cash__image} alt="" />

        {data?.dashboard_message4?.includes("/") ? (
          <div className="mb-2">
            <h3>{data?.dashboard_message4.split("/")[0]}</h3>
            <h1>{data?.dashboard_message4.split("/")[1]}</h1>
          </div>
        ) : (
          <div className="mb-2">
            {data?.dashboard_message5 && <h3>{data?.dashboard_message5}</h3>}

            {data?.dashboard_message4?.includes("₹") ? (
              <h1>{data?.dashboard_message4}</h1>
            ) : (
              <h3 style={{ fontWeight: "500" }}>{data?.dashboard_message4}</h3>
            )}
          </div>
        )}

        {data.showButton ? (
          <div className={styles.button}>
            <CustomButton
              text={data?.dashboard_message3}
              endIcon={!data.isKycFailure ? <FaArrowRightLong /> : null}
              onClick={() => {
                if (data.showContactPage) {
                  navigate("/contact-us");
                } else if (data.isRejected) {
                  navigate("/loan-reject");
                } else if (data?.step?.current_route === "/pan-verify") {
                  navigate(data?.step?.current_route);
                } else if (data?.step?.current_route === "/enter-aadhar") {
                  navigate(data?.step?.current_route);
                } else if (data?.lead?.status === "Approved Process") {
                  navigate("/loan-approval");
                } else {
                  handleStep();
                }

                WebEngageTrackingEvent("Get Cash", {
                  customer_type: localStorage.getItem("customerType"),
                  app_version: localStorage.getItem("appVersion"),
                });
              }}
              size="lg"
              disabled={!data.buttonEnable}
            />
          </div>
        ) : (
          <h1>{data?.dashboard_message3}</h1>
        )}

        {data.completionPercentage !== null && (
          <div>
            {data.completionPercentage > 0 ? (
              <div className={`${styles.features} ${styles.steps}`}>
                <div>
                  <div className={styles.progress__bar}>
                    <div className={styles.progress__percent} style={{ width: `${data.completionPercentage}%` }}></div>
                  </div>
                  <img src="/images/kyc-check.png" alt="" />
                  <span>
                    KYC <br /> Check
                  </span>
                </div>
                <div>
                  <img src="/images/basic-information.png" alt="" />
                  <span>
                    Basic <br /> Information
                  </span>
                </div>
                <div>
                  <img src="/images/get-disbursal.png" alt="" />
                  <span>
                    Get <br /> Disbursal
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles.features}>
                <div>
                  <img src="/images/insta-loan.png" alt="" />
                  <span>
                    Instant <br /> Loan
                  </span>
                </div>
                <div>
                  <img src="/images/paperless.png" alt="" />
                  <span>
                    100% <br /> Paperless
                  </span>
                </div>
                <div>
                  <img src="/images/calendra.png" alt="" />
                  <span>
                    Flexible <br /> EMIs
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.loanMessages}>
        <img src="/images/" alt="" />

        <span>Just 2 minutes to get up to ₹8 Lakh! Act fast!</span>
      </div>
    </>
  );
}
