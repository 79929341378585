import React, { useEffect, useState } from "react";
import styles from "./SalaryDate.module.scss";
import { useSelector, useDispatch } from "react-redux";

// components
import MainHeader from "../../components/Header/MainHeader";
import Loader from "../../components/Loader/Loader";

// services
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addSalaryDate } from "../../redux/slices/registerSlice";
import { useNavigate } from "react-router-dom";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";

export default function SalaryDate() {
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);

  const { salaryDate } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { incompleteDetailsUpdate } = new ServicesAPI();
  const { handleStep } = useResponse();
  const navigate = useNavigate();

  useEffect(() => {
    generateDates();
  }, []);

  const handleIncompleteDetailsUpdateAPI = async (data) => {
    setLoading(true);
    const res = await incompleteDetailsUpdate(data);

    if (res && res.statusCode === 200) {
      dispatch(addSalaryDate(data.value));

      if (repeatCustomer) {
        navigate("/confirm-details");
      } else {
        handleStep();
      }

      WebEngageUserAttributes("Salary Date", parseInt(data.value));
    }

    setLoading(false);
  };

  const generateDates = () => {
    const newDates = [];
    for (let i = 1; i <= 31; i++) {
      newDates.push(i);
    }
    setDates(newDates);
  };

  return (
    <>
      <div className={`wrapper ${styles.salaryDateStyle}`}>
        <MainHeader
          title="Employment Details"
          showProgress={true}
          percent={84.59}
          backPageName={repeatCustomer ? "/confirm-details" : ""}
        />
        <div className="mb-3">
          <p className="label">Date of Salary</p>
        </div>

        <div className={styles.dates}>
          {dates.map((item, index) => (
            <div
              className={`${styles.date} ${salaryDate === item ? styles.active : ""}`}
              key={index}
              onClick={() => {
                handleIncompleteDetailsUpdateAPI({
                  key: "salary_date",
                  value: item,
                });
                WebEngageTrackingEvent("Salary Date Submitted", {
                  "Salary Date": parseInt(item),
                  customer_type: localStorage.getItem("customerType"),
                  app_version: localStorage.getItem("appVersion"),
                });
              }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
}
