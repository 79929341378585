import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

// components
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import ContactMessage from "../../components/Messages/ContactMessage";
import InfoMessage from "../../components/Messages/InfoMessage";
import MainHeader from "../../components/Header/MainHeader";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { enterPanValidation } from "../../validations/user.validation";
import { addPanVerifyData } from "../../redux/slices/registerSlice";
import PanExistModal from "../../components/Modal/PanExistModal";
import Swal from "sweetalert2";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import { CustomButton } from "../../components";

export default function EnterPan() {
  const [openPanModal, setOpenPanModal] = useState({ bottom: false, mobileNo: "" });
  const dispatch = useDispatch();
  const { handleStep } = useResponse();

  const navigate = useNavigate();

  const { panVerification } = new ServicesAPI();

  return (
    <div className="wrapper">
      <Formik
        initialValues={{ pan: "" }}
        validationSchema={enterPanValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          setTimeout(async () => {
            const res = await panVerification(values.pan);

            if (res.statusCode === 200) {
              dispatch(addPanVerifyData(res.data));
              WebEngageTrackingEvent("PAN Submitted", {
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });

              navigate("/pan-verify");
            } else if (res.statusCode === 400) {
              const data = res?.data?.mobileNo;
              if (data) {
                setOpenPanModal({ ...openPanModal, bottom: true, mobileNo: data });
              } else {
                Swal.fire({
                  icon: "error",
                  text: res.message,
                  timer: 3000,
                });
              }
            } else {
              Swal.fire({
                icon: "error",
                text: res.message,
                timer: 3000,
              });
            }

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <MainHeader title="Pan Verification" />
                <p className="label">Enter your PAN</p>
                <div className="sub-label mb-4">
                  Please enter your <strong>PAN</strong> number
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your PAN"
                    name="pan"
                    value={values.pan}
                    onChange={(e) => {
                      setFieldValue("pan", e.target.value);
                    }}
                    // onInput={(e) => {
                    //   setFieldValue("pan", e.target.value);
                    // }}
                    onBlur={(e) => {
                      setFieldValue("pan", e.target.value.trim());
                    }}
                    autoComplete="off"
                    maxLength={10}
                    style={{ textTransform: "uppercase" }}
                  />

                  {touched.pan && errors.pan ? <div className="error-msg">{errors.pan}</div> : null}
                </div>

                <InfoMessage message="Your PAN number should be like this: RRRRR0000R" />

                <ContactMessage />
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />

                <CustomButton text="Next" type="submit" />
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>

      <PanExistModal openPanModal={openPanModal} setOpenPanModal={setOpenPanModal} />
    </div>
  );
}
