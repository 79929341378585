// User Attributes
export const WebEngageUserAttributes = (attribute, value) => {
  switch (attribute) {
    case "userId":
      window.webengage && window.webengage.user.login(value);
      break;

    default:
      window.webengage && window.webengage.user.setAttribute(attribute, value);
  }
};

// Tracking Event
export const WebEngageTrackingEvent = (eventName, eventData) => {
  window.webengage && window.webengage.track(eventName, eventData);
};
