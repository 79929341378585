import React from "react";
import "./DataSafetyMessage.scss";

export default function DataSafetyMessage() {
  return (
    <div className="data-safety">
      <div className="safety-inner">
        <img src="/images/privacy.svg" alt="" className="image" />
        <p className="text">Your data is safe with us</p>
      </div>
    </div>
  );
}
