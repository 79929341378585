import React, { useState } from "react";
import "./SelectLoan.scss";

// components
import MainHeader from "../../components/Header/MainHeader";
import Loader from "../../components/Loader/Loader";
import CustomButton from "../../components/Button/CustomButton";

export default function SelectLoan() {
  const [loading, setLoading] = useState(false);

  const array = [
    {
      name: "One-time Repayment",
      desc: "Pay the full loan amount in one payment.",
      loanAmount: "₹12000",
      tenure: "34 Days",
      images: "/images/loan/one-time.png",
    },
    {
      name: "2-Month EMI Plan",
      desc: "Spread the repayment over 2 months with convenient EMIs.",
      loanAmount: "₹12000",
      tenure: "2 months",
      images: "/images/loan/2-month.png",
    },
    {
      name: "6-Month EMI Plan",
      desc: "Opt for a 6-month EMI plan with lower monthly installments.",
      loanAmount: "₹12000",
      tenure: "6 months",
      images: "/images/loan/6-month.png",
    },
  ];

  return (
    <>
      <div className="wrapper selectLoan__page">
        <MainHeader title="Select your Loan" />

        <div className="mb-3">
          <p className="label">Please select a repayment option for your loan disbursal</p>
        </div>

        {array.map((item, index) => (
          <div className={`loan-lists ${array.length === 1 ? "single" : ""}`} key={index}>
            <div className="content">
              <h3>{item.name}</h3>
              <p>{item.desc}</p>

              <div className="loan-details">
                <div>
                  <p className="value">{item.loanAmount}/-</p>
                  <p className="label">Loan Amount</p>
                </div>
                <div>
                  <p className="value">{item.tenure}</p>
                  <p className="label">Loan Tenure</p>
                </div>
              </div>
            </div>
            <div className="image">
              <img src={item.images} alt="" />
            </div>
          </div>
        ))}

        <div className="bottom-button-fixed">
          <CustomButton text="Continue" />
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
}
