import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import MainHeader from "../../components/Header/MainHeader";
import SelectOption from "../../components/Select/SelectOption";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addYourResidence } from "../../redux/slices/registerSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";

export default function YourResidence() {
  const [loading, setLoading] = useState(false);

  const { yourResidence } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enterYourResidence } = new ServicesAPI();
  const { handleStep } = useResponse();

  const data = ["Owned", "Rented"];

  const handleIncompleteDetailsUpdateAPI = async (data) => {
    setLoading(true);

    const res = await enterYourResidence(data);

    if (res && res.statusCode === 200) {
      dispatch(addYourResidence(data.value));

      WebEngageUserAttributes("Residential Type", data.value);

      if (repeatCustomer) {
        navigate("/confirm-details");
      } else {
        handleStep();
      }
    } else if (res && res.statusCode === 400) {
      Swal.fire({
        icon: "error",
        text: res.message,
        timer: 3000,
      });

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="wrapper">
        <MainHeader
          title="Residential Details"
          showProgress={true}
          percent={92.28}
          backPageName={repeatCustomer ? "/confirm-details" : ""}
        />
        <div className="mb-3">
          <p className="label">Your Residence</p>
        </div>

        {data.map((item, index) => (
          <div
            key={index}
            onClick={async () => {
              handleIncompleteDetailsUpdateAPI({
                key: "residenceType",
                value: item,
              });

              WebEngageTrackingEvent("Residence Type Confirmed", {
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          >
            <SelectOption name={item} checked={yourResidence} />
          </div>
        ))}
      </div>

      {loading && <Loader />}
    </>
  );
}
