import React from "react";
import "./Modal.scss";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";

export default function PanExistModal(props) {
  const { openPanModal, setOpenPanModal } = props;

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={openPanModal["bottom"]}
        onClose={() => setOpenPanModal({ ...openPanModal, bottom: false })}
      >
        <div className="exist__modal">
          <img src="/images/pan-card.png" alt="" />
          <h3>This PAN number is associated with another existing account</h3>
          <p>
            Associated number <span>{openPanModal?.mobileNo}</span>
          </p>
          <p>
            If you believe it belongs to you, kindly raise a dispute at{" "}
            {/* <Link to="mailto:info@ramfincorp.com">info@ramfincorp.com</Link> */}
            <Link to="javascript:void(0)">info@ramfincorp.com</Link>
          </p>
        </div>
      </Drawer>
    </div>
  );
}
