import React, { useEffect, useState } from "react";
import styles from "./EnterAadhaar.module.scss";

// libraries
import { Formik } from "formik";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";

// components
import Loader from "../../components/Loader/Loader";
import MainHeader from "../../components/Header/MainHeader";
import ContactMessage from "../../components/Messages/ContactMessage";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { enterAadhaarValidation } from "../../validations/user.validation";
import AadhaarExistModal from "../../components/Modal/AadhaarExistModal";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";

export default function EnterAadhaar() {
  const [isAadharDown, setAadharDown] = useState(false);
  const [openAadhaarModal, setOpenAadhaarModal] = useState({ bottom: false, mobileNo: "" });
  const [selectOption, setSelectOption] = useState("");
  const [isOTPVisible, setOTPVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(null);

  const { handleStep } = useResponse();

  const {
    checkAadhaarStatusAPI,
    generateAadhaarOTP,
    aadhaarVerificationOTP,
    aadhaarPANVerify,
    aadhaarVerificationInitiateDigilocker,
  } = new ServicesAPI();

  useEffect(() => {
    const fetchAadhaarStatus = async () => {
      const res = await checkAadhaarStatusAPI();
      if (res && res.statusCode === 200) {
        setAadharDown(res.data.isAadharDown);

        if (res.data.isAadharDown === true) {
          setSelectOption("digilocker");
        } else {
          setSelectOption("aadhaar");
        }
      }
    };

    fetchAadhaarStatus();
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSecond) => prevSecond - 1);
      }, 1000);

      convertSecondsToMinutes();

      return () => clearInterval(interval);
    }
  }, [seconds]);

  const handleResendAadhaarOTP = async (aadhaar) => {
    const res = await generateAadhaarOTP(aadhaar);

    if (res?.statusCode === 200) {
      setSeconds(600);
    } else {
      Swal.fire({
        icon: "error",
        text: res.message,
      });
    }
  };

  const handleDigilockerAPI = async () => {
    const res = await aadhaarVerificationInitiateDigilocker();

    if (res && res?.statusCode === 200) {
      window.location.href = res.data.url;
    }
  };

  function convertSecondsToMinutes() {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minute ${remainingSeconds}`;
  }

  // console.log("selectOption-------------", selectOption);

  if (!selectOption) {
    return <Loader />;
  }
  return (
    <div className="wrapper auto-height">
      <Formik
        initialValues={{ aadhaar: "" }}
        validationSchema={selectOption === "aadhaar" ? enterAadhaarValidation : null}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          setTimeout(async () => {
            if (selectOption === "aadhaar") {
              if (!isOTPVisible) {
                const res = await generateAadhaarOTP(values.aadhaar);

                // console.log("res-----------", res);

                if (res && res.statusCode === 200) {
                  setOTPVisible(true);
                  setSeconds(600);

                  WebEngageTrackingEvent("KYC Verification Initiated", {
                    "Method Selected": " Via Aadhaar OTP",
                    customer_type: localStorage.getItem("customerType"),
                    app_version: localStorage.getItem("appVersion"),
                  });
                } else if (res.statusCode === 400) {
                  const data = res?.data?.mobileNo;
                  if (data) {
                    setOpenAadhaarModal({ ...openAadhaarModal, bottom: true, mobileNo: data });
                  } else {
                    Swal.fire({
                      icon: "error",
                      text: res.message,
                      timer: 3000,
                    });
                  }
                } else {
                  Swal.fire({
                    icon: "error",
                    text: res?.message,
                    timer: 3000,
                  });
                }
              } else {
                if (otp.length === 6) {
                  const res = await aadhaarVerificationOTP(values.aadhaar, otp);
                  if (res) {
                    const res = await aadhaarPANVerify();

                    // console.log("res---------->>>>>>", res);

                    if (res && res.statusCode === 200) {
                      Swal.fire({
                        position: "top-center",
                        icon: "success",
                        title: "Verified Successfully",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        timer: 2000,
                      });
                      setTimeout(() => {
                        handleStep();
                      }, 2000);

                      WebEngageTrackingEvent("KYC Verification Completed", {
                        customer_type: localStorage.getItem("customerType"),
                        app_version: localStorage.getItem("appVersion"),
                      });
                    } else if (res.statusCode === 400) {
                      Swal.fire({
                        text: res.message,
                        icon: "error",
                        confirmButtonText: "OK",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.href = res.data.route;
                        }
                      });
                    } else {
                      Swal.fire({
                        icon: "error",
                        text: res.message,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                      });
                    }
                  }
                } else {
                  Swal.fire({
                    icon: "warning",
                    text: "Please enter aadhaar otp",
                  });
                }
              }
            } else {
              handleDigilockerAPI();
            }

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className={`vertical-column-layout ${styles.aadhaar__styles}`}>
              <div className="vertical-column-top">
                <MainHeader title="KYC Verification" showProgress={true} percent={88} />
                <p className="label mb-1">Verify Aadhaar</p>

                <div className={`${styles.kycOptions} ${isAadharDown ? "" : styles.down}`}>
                  <div
                    className={`${styles.selectOption} ${selectOption == "digilocker" ? styles.selected : ""}`}
                    onClick={() => setSelectOption("digilocker")}
                  >
                    <div className={styles.selectDigilocker}>
                      <div className={styles.verifyImage}>
                        <img src="/images/digiloker.svg" width="60" />
                      </div>
                      <div className={styles.text}>Verify with DigiLocker</div>
                    </div>
                    {isAadharDown && <div className={styles.recommended}>Recommended</div>}
                  </div>

                  <div
                    className={`${styles.selectOption} ${selectOption == "aadhaar" ? styles.selected : ""}`}
                    onClick={() => setSelectOption("aadhaar")}
                  >
                    {!isAadharDown && <div className={styles.recommended}>Recommended</div>}
                    <div className={styles.selectDigilocker}>
                      <div className={styles.verifyImage}>
                        <img src="/images/adharcard.png" width="60" />
                      </div>
                      <div className={`${styles.text} ${styles.bigText}`}>Via Aadhar OTP</div>
                    </div>

                    <div className="form-group mt-1">
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter your aadhaar number"
                        name="aadhaar"
                        autoComplete="off"
                        value={values.aadhaar}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (input != "000000000000" && /^\d*$/.test(input)) {
                            setFieldValue("aadhaar", e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          setFieldValue("aadhaar", e.target.value.trim());
                        }}
                        maxLength={12}
                        disabled={isOTPVisible ? true : false}
                      />

                      {touched.aadhaar && errors.aadhaar ? <div className="error-msg">{errors.aadhaar}</div> : null}

                      {values.aadhaar.length === 12 && !errors.aadhaar && (
                        <div className={styles.check}>
                          <img src="/images/right.svg" alt="" />
                        </div>
                      )}
                    </div>

                    {isOTPVisible && (
                      <>
                        <div className="mt-1">
                          <div className={`${styles.text} ${styles.bigText} ml-0`}>Enter OTP</div>
                          <div className={styles.otpInputs}>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              inputType="tel"
                              renderSeparator={<span>&nbsp;</span>}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                        </div>
                        <div>
                          <p className={styles.notReceivedMsg}>Have not received your OTP ?</p>
                          <p className={styles.notReceivedMsg}>
                            <span
                              className={styles.resend}
                              onClick={() => {
                                if (seconds === 0) {
                                  handleResendAadhaarOTP(values.aadhaar);
                                }
                              }}
                            >
                              Resend
                            </span>{" "}
                            {seconds > 0 && <span>in {convertSecondsToMinutes()} seconds</span>}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <ContactMessage />
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />

                <CustomButton text="Next" type="submit" />
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>

      <AadhaarExistModal openAadhaarModal={openAadhaarModal} setOpenAadhaarModal={setOpenAadhaarModal} />
    </div>
  );
}
