import * as Yup from "yup";

export const enterNameValidation = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Please enter valid name")
    .required("Name is required")
    .min(3, "Name is too short"),
  email: Yup.string().email("Invalid email Id").required("Email is required"),
});

export const enterPanValidation = Yup.object({
  pan: Yup.string()
    .matches(/^([A-Za-z]){3}([pP])([A-Za-z]){1}([0-9]){4}([A-Za-z]){1}$/, "Please enter valid PAN number")
    .required("PAN is required"),
});

export const enterAadhaarValidation = Yup.object({
  aadhaar: Yup.string()
    .matches(/^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/, "Please enter valid aadhaar number")
    .required("Please enter aadhaar number"),
});

export const enterLoanAmountValidation = Yup.object({
  loanAmount: Yup.number()
    .min(1000, "Amount must be at least 1000")
    .max(999999, "Maximun amount should be 999999")
    .required("Please enter loan amount"),
});

export const enterCompanyNameValidation = Yup.object({
  companyName: Yup.string()
    .matches(/^(?![0-9]+$)/, "Input cannot be only numbers")
    .min(2, "Company name should be min 2 char")
    .max(50, "Company name should be max 50 char")
    .required("Please enter your company name"),
});

export const monthlyIncomeValidation = Yup.object({
  monthlyIncome: Yup.number().min(1000, "Amount must be at least 1000").required("Please enter your monthly income"),
});

export const currentAddressValidation = Yup.object({
  houseNo: Yup.string().required("Please enter your house no."),
  city: Yup.string().required("Please enter your city"),
  state: Yup.string().required("Please enter your state"),
  pinCode: Yup.string().required("Please enter your pincode"),
  // landmark: Yup.string().required("Please enter your nearby landmark"),
});

export const referenceDetailsValidation = Yup.object({
  nameOne: Yup.string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Please enter valid name")
    .required("Please enter referred name"),
  relationOne: Yup.string().required("Please select relation"),
  mobileOne: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Please enter valid mobile number")
    .required("Mobile number is required")
    .min(10, "Mobile number should be min 10 char."),

  nameTwo: Yup.string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Please enter valid name")
    .required("Please enter referred name"),
  relationTwo: Yup.string().required("Please select relation"),
  mobileTwo: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Please enter valid mobile number")
    .required("Mobile number is required")
    .min(10, "Mobile number should be min 10 char."),
});

export const bankDetailsValidation = Yup.object({
  accountHolderName: Yup.string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Please enter valid account holder name")
    .required("Please enter account holder name"),
  accountNo: Yup.string()
    .matches(/^[0-9]+$/, "Please enter valid account number")
    .required("Please enter account number")
    .min(10, "Account number must be at least 10 digits")
    .max(18, "Account number cannot be longer than 18 digits"),
  confirmedAccountNo: Yup.string()
    .oneOf([Yup.ref("accountNo"), null], "Re-enter account number must match")
    .required("Please re-enter account number"),
  ifsc: Yup.string()
    // .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Please enter valid IFSC code")
    .required("Please enter IFSC code"),
  bankName: Yup.string().required("Please enter bank name"),
});
