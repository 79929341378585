import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// import counterReducer from "./slices/counterSlice";
import registerReducer from "./slices/registerSlice";
import userReducer from "./slices/userSlice";

// Persist config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "register"], // You can choose which reducer to persist (e.g., 'register')
};

// Combine reducers
const rootReducer = combineReducers({
  // counter: counterReducer,
  register: registerReducer,
  user: userReducer,
});

// Persist the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disables the warning about non-serializable values in the state (needed for redux-persist)
    }),
});

// Create a persistor
export const persistor = persistStore(store);
