import React from "react";
import styles from "./Header.module.scss";
import { getEnvironment } from "../../utils/environment";

export default function Header() {
  const { PRODUCT } = getEnvironment();

  return (
    <header className={styles.header}>
      <img src={PRODUCT === "ramfincorp_api" ? "/images/logo.png" : "/images/logo2.png"} alt="" />
    </header>
  );
}
