import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import MainHeader from "../../components/Header/MainHeader";
import SelectOption from "../../components/Select/SelectOption";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addEmploymentDetails } from "../../redux/slices/registerSlice";
import { useNavigate } from "react-router-dom";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";

export default function EmployementDetails() {
  const [loading, setLoading] = useState(false);

  const { employmentDetails } = useSelector((state) => state.register);
  const { repeatCustomer } = useSelector((state) => state.user);

  // console.log("repeatCustomer-------", repeatCustomer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { incompleteDetailsUpdate } = new ServicesAPI();
  const { handleStep } = useResponse();

  const data = ["Salaried", "Self Employee"];

  const handleIncompleteDetailsUpdateAPI = async (data) => {
    setLoading(true);

    const res = await incompleteDetailsUpdate(data);

    if (res && res.statusCode === 200) {
      dispatch(addEmploymentDetails(data.value));

      if (repeatCustomer) {
        navigate("/confirm-details");
      } else {
        handleStep();
      }

      WebEngageUserAttributes("Employment Type", data.value);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="wrapper">
        <MainHeader
          title="Professional Details"
          showProgress={true}
          percent={30.76}
          backPageName={repeatCustomer ? "/confirm-details" : ""}
        />

        <div className="mb-3">
          <p className="label">Employement Details</p>
        </div>

        {data.map((item, index) => (
          <div
            key={index}
            onClick={async () => {
              handleIncompleteDetailsUpdateAPI({
                key: "employeeType",
                value: item,
              });
              WebEngageTrackingEvent("Employment Details Submitted", {
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          >
            <SelectOption name={item} checked={employmentDetails} />
          </div>
        ))}
      </div>

      {loading && <Loader />}
    </>
  );
}
