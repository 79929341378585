import { getEnvironment } from "../utils/environment";

const { PRODUCT } = getEnvironment();

// Themes
export const themes = {
  ramfincorp: {
    primaryColor: "#182bda",
    secondaryColor: "#4051eb",
  },
  kamakshi: {
    // primaryColor: "#a3001d",
    // secondaryColor: "#EB8503",
    primaryColor: "#860002",
    secondaryColor: "#db4a0f",
  },
};

export const selectedTheme = () => {
  if (PRODUCT === "ramfincorp_api") {
    return themes.ramfincorp;
  } else {
    return themes.kamakshi;
  }
};
