import React from "react";
import styles from "./CustomButton.module.scss";

export default function CustomButton(props) {
  const { text, type, endIcon, size, onClick, disabled, variant } = props;

  return (
    <div className={styles.button__container}>
      <button
        type={type ? type : "button"}
        className={`${styles.custom__button} ${size === "lg" && styles.large} ${
          variant === "outline" && styles.outline
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        <span>{text}</span> {endIcon}
      </button>
      <i className={styles.shine}></i>
    </div>
  );
}
