import React from "react";
import { Link } from "react-router-dom";
import "./ContactMessage.scss";

export default function ContactMessage() {
  return (
    <div className="contact-message">
      Facing any issue? <Link to="/contact-us">Contact us</Link>
    </div>
  );
}
