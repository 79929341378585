import React, { useEffect, useState } from "react";
import styles from "./PanVerify.module.scss";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useSelector } from "react-redux";

// components
import MainHeader from "../../components/Header/MainHeader";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { enterPanValidation } from "../../validations/user.validation";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";

export default function PanVerify() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { panVerifyData } = useSelector((state) => state.register);

  const { handleStep } = useResponse();

  const { panConfirmation } = new ServicesAPI();

  useEffect(() => {
    if (!panVerifyData) {
      navigate("/enter-your-pan");
    }
  }, []);

  const handlePanConfirmation = async () => {
    setLoading(true);

    const res = await panConfirmation(panVerifyData?.pan_number);

    if (res) {
      handleStep();

      WebEngageUserAttributes("First Name", panVerifyData?.full_name_split[0]);
      WebEngageUserAttributes("Last Name", panVerifyData?.full_name_split[1]);
      WebEngageUserAttributes("Birth Date", new Date(panVerifyData?.dob));

      WebEngageTrackingEvent("PAN Details Confirmed", {
        "Response Clicked": "Yes",
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
    }

    setLoading(false);
  };

  return (
    <div className="wrapper auto-height">
      <MainHeader title="Pan Verification" />
      <Formik initialValues={{}} validationSchema={enterPanValidation} onSubmit={(values) => {}}>
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.panVerifyStyles}>
              <div className="mb-2">
                <p className="label">PAN Number</p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your PAN"
                    name="pan"
                    value={panVerifyData?.pan_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    readOnly
                  />

                  <div className={styles.check}>
                    <img src="/images/right.svg" alt="" />
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <p className="label">Name</p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your PAN"
                    name="pan"
                    value={panVerifyData?.full_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    readOnly
                  />
                </div>
              </div>

              <div className="mb-2">
                <p className="label">D.O.B</p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your PAN"
                    name="pan"
                    value={panVerifyData?.dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    readOnly
                  />
                </div>
              </div>

              <div className={styles.information}>
                <p>Are These Information Correct?</p>

                <div className={styles.buttons}>
                  <button
                    type={styles.button}
                    onClick={() => {
                      WebEngageTrackingEvent("PAN Details Confirmed", {
                        "Response Clicked": "No",
                        customer_type: localStorage.getItem("customerType"),
                        app_version: localStorage.getItem("appVersion"),
                      });
                      navigate("/enter-your-pan");
                    }}
                  >
                    No
                  </button>
                  <button type="submit" onClick={() => handlePanConfirmation()}>
                    Yes
                  </button>
                </div>
              </div>

              <br />
            </div>

            {loading && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
