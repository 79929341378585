import React, { useEffect } from "react";
import AppRoute from "./routes/AppRoute";
import { selectedTheme } from "./config/themes";

export default function App() {
  const theme = selectedTheme();

  useEffect(() => {
    Object.keys(theme).forEach((key) => {
      document.documentElement.style.setProperty(`--${key}`, theme[key]);
    });
  }, []);

  return (
    <>
      <AppRoute />
    </>
  );
}
