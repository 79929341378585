// UTM Source URL
export const utmSourceURL = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const query = queryParams.get("utm_source");

  if (query) {
    localStorage.setItem("utm_source", query);
    return `?utm_source=${query}`;
  } else {
    return "";
  }
};

// UTM Source Storage
export const utmSourceStorage = () => {
  const source = localStorage.getItem("utm_source");

  if (source) {
    return `?utm_source=${source}`;
  } else {
    return "";
  }
};

// Add Customer Type & App Version
export const storeCustomerTypeAndAppVersion = (customerType, appVersion) => {
  if (customerType && appVersion) {
    localStorage.setItem("customerType", customerType);
    localStorage.setItem("appVersion", appVersion);
  }
};
