import React from "react";
import styles from "./NotFound.module.scss";

export default function NotFound() {
  return (
    <div className={styles.not__found}>
      <div className="wrapper">
        <img src="/images/not-found.svg" className={styles.image} alt="" />

        <h1>Ooops...</h1>
        <h3>Page not found</h3>
        <p>We couldn't find what you looking for.</p>
      </div>
    </div>
  );
}
