import Swal from "sweetalert2";

// API Error Handling
export const serviceError = (error) => {
  if (error.response) {
    // const data = error.response.data;
    const statusCode = error.response.data.statusCode;
    const message = error.response.data.message;

    switch (statusCode) {
      case 412:
        Swal.fire({
          icon: "error",
          text: message,
        });
        console.log("Validation Error: Invalid input.");
        break;

      case 400:
        Swal.fire({
          icon: "error",
          text: message,
        });
        console.log("Bad Request: Invalid input.");
        break;

      case 404:
        Swal.fire({
          icon: "error",
          text: message,
        });
        console.log("Not Found: The requested resource was not found.");
        break;

      case 428:
        Swal.fire({
          icon: "error",
          text: message,
        });
        console.log("Step Error: Previous step not completed");
        break;

      case 500:
        Swal.fire({
          icon: "error",
          title: "Internal Server Error",
          text: "Something went wrong on the server.",
        });
        console.log("Internal Server Error: Something went wrong on the server.");
        break;

      default:
        console.log(`Unhandled error with status code: ${error}`);
    }

    console.log("API Error:", error.response.data);
  } else {
    Swal.fire({
      icon: "error",
      text: error.message,
      timer: 2000,
    });

    if (error?.code === "ERR_CANCELED") {
      window.location.href = "/logout";
    }

    console.log("Error in setting up request", error);
  }
};
