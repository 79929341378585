import React from "react";
import styles from "./LoanSummary.module.scss";
import { IoIosArrowBack, IoIosArrowRoundForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

export default function LoanSummary(props) {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.loanSummary}>
        <div className={styles.header}>
          <Link to="/home" className={styles.backArrow}>
            <IoIosArrowBack size={34} color="#ffffff" />
          </Link>
          <p>Repayment</p>
        </div>

        <div className={styles.paymentAmount}>
          <p>Total Re-payment Amount</p>
          <h3>₹ 17,999</h3>
        </div>
      </div>

      <div className={styles.loanDetails}>
        <p className={styles.title}>Loan Summary</p>
        <div className={styles.detailList}>
          <div>
            <p className={styles.label}>Loan Account Number</p>
            <p className={styles.value}>ASDSBSDK322SHJSD09</p>
          </div>
          <div>
            <p className={styles.label}>Loan Amount(₹)</p>
            <p className={styles.value}>15,000</p>
          </div>
        </div>

        <div className={styles.detailList}>
          <div>
            <p className={styles.label}>EMI Amount(₹)</p>
            <p className={styles.value}>1,200.00</p>
          </div>
          <div>
            <p className={styles.label}>Loan Tenure</p>
            <p className={styles.value}>12 Months</p>
          </div>
        </div>

        <div className={styles.detailList}>
          <div>
            <p className={styles.label}>Due date</p>
            <p className={`${styles.value} ${styles.dueDate}`}>
              1 Jul, 2024 <span className={styles.due}>Due</span>
            </p>
          </div>
          <div>
            <p className={styles.label}>Last payment date</p>
            <p className={styles.value}>1 Jun, 2024</p>
          </div>
        </div>
      </div>
    </>
  );
}
