import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";

export default function EmandateStatus() {
  useEffect(() => {
    checkMandateStatus();
  }, []);

  const checkMandateStatus = () => {
    setTimeout(() => {
      window.location.href = "/emandate";
    }, 2000);
  };

  return (
    <div>
      <Loader />
    </div>
  );
}
