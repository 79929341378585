import React, { useState } from "react";
import LoanSummary from "./components/LoanSummary/LoanSummary";
import Tabs from "./components/Tabs/Tabs";
import EMICalendar from "./components/EMICalendar/EMICalendar";
import Transactions from "./components/Transactions/Transactions";
import Documents from "./components/Documents/Documents";

export default function Repayment() {
  const [tab, setTab] = useState("EMI Calendar");

  return (
    <div className="wrapper bg-white" style={{ height: "auto" }}>
      <LoanSummary />
      <Tabs tab={tab} setTab={setTab} />
      {tab === "EMI Calendar" && <EMICalendar />}
      {tab === "Transactions" && <Transactions />}
      {tab === "Documents" && <Documents />}
    </div>
  );
}
