import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./MainHeader.scss";

export default function MainHeader({ title, showProgress, percent, backPageName }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleBackNavigation = () => {
    switch (pathname) {
      case "/enter-your-pan":
        return "/enter-name";

      case "/pan-verify":
        return "/enter-your-pan";

      case "/enter-aadhar":
        return "/dashboard";

      case "/loan-purpose":
        return backPageName || "/dashboard";

      case "/enter-loan-amount":
        return backPageName || "/loan-purpose";

      case "/gender":
        return "/enter-loan-amount";

      case "/marital-status":
        return backPageName || "/gender";

      case "/highest-education":
        return backPageName || "/marital-status";

      case "/employment-details":
        return backPageName || "/highest-education";

      case "/payment-mode":
        return backPageName || "/employment-details";

      case "/company-name":
        return backPageName || "/payment-mode";

      case "/industry":
        return backPageName || "/company-name";

      case "/your-designation":
        return backPageName || "/industry";

      case "/monthly-income":
        return backPageName || "/your-designation";

      case "/work-experience":
        return "/monthly-income";

      case "/salary-date":
        return backPageName || "/work-experience";

      case "/your-residence":
        return backPageName || "/salary-date";

      case "/enter-address":
        return backPageName || "/your-residence";

      case "/bank-details":
        return backPageName || "/dashboard";

      case "/term-condition":
        return backPageName || "/dashboard";

      case "/contact-us":
        return backPageName || "/dashboard";

      default:
        return "/dashboard";
    }
  };

  return (
    <>
      <div className="main-header">
        <div className="header-inner">
          <div
            className="back-button"
            onClick={() => {
              navigate(handleBackNavigation());
              // window.location.href = handleBackNavigation();
            }}
          >
            <img src="/images/back_icon.svg" className="back-icon" alt="" />
          </div>
          <h1 className="header-title">{title}</h1>
        </div>

        {showProgress && (
          <div className="header-progress">
            <div className="text">{percent}%</div>
            <div className="progress-bar">
              <div className="progress-completed" style={{ width: `${percent}%` }}></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
