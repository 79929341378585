import React from "react";
import styles from "./Tabs.module.scss";

export default function Tabs(props) {
  const { tab, setTab } = props;

  const tabs = ["EMI Calendar", "Transactions", "Documents"];

  return (
    <div className={styles.tabs}>
      {tabs.map((item, index) => (
        <button className={item === tab ? styles.active : ""} key={index} onClick={() => setTab(item)}>
          {item}
        </button>
      ))}
    </div>
  );
}
