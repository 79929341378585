import React from "react";
import styles from "./Contact.module.scss";

// components
import MainHeader from "../../components/Header/MainHeader";
import { Link } from "react-router-dom";

import { HiOutlineMail } from "react-icons/hi";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { getEnvironment } from "../../utils/environment";

export default function Contact() {
  const { PRODUCT } = getEnvironment();

  const contact = {
    ramfincorp: {
      email: "info@ramfincorp.com",
      phone: "9899985495",
    },
    kamakshi: {
      email: "info@kamakshimoney.com",
      phone: "9220429444",
    },
  };

  const selectedAddress = PRODUCT === "ramfincorp_api" ? contact.ramfincorp : contact.kamakshi;

  return (
    <>
      <div className={`wrapper ${styles.contact__us}`}>
        <MainHeader title="Contact Us" showProgress={false} backPageName="/home" />
        <div className="mb-3">
          <p className="label">Contact us for all your queries</p>
        </div>

        <Link to={`mailto:${selectedAddress.email}`} className={styles.list}>
          <div className={styles.icon}>
            <HiOutlineMail size={22} />
          </div>
          <div className={styles.content}>
            <div>
              <h5>{selectedAddress.email}</h5>
              <span>Click here to drop an email to us</span>
            </div>
            <div className={styles.arrow}>
              <MdOutlineKeyboardArrowRight color="#000000" size={24} />
            </div>
          </div>
        </Link>

        <Link to={`tel:${selectedAddress.phone}`} className={styles.list}>
          <div className={styles.icon}>
            <LuPhoneCall size={22} />
          </div>
          <div className={styles.content}>
            <div>
              <h5>+91 {selectedAddress.phone}</h5>
              <span>Click here to call us</span>
            </div>
            <div className={styles.arrow}>
              <MdOutlineKeyboardArrowRight color="#000000" size={24} />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
