import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  userName: "",
  upgradeLoanAmount: false,
  repeatCustomer: false,
  isLoanApproved: false,
  accountId: "",
  leadType: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.user = action.payload;
    },

    addUserName: (state, action) => {
      state.userName = action.payload;
    },

    addUpgradeLoanAmount: (state) => {
      state.upgradeLoanAmount = true;
    },

    addRepeatCustomer: (state, action) => {
      state.repeatCustomer = action.payload;
    },

    changeLoanApproved: (state, action) => {
      state.isLoanApproved = action.payload;
    },

    addAccountId: (state, action) => {
      state.accountId = action.payload;
    },

    addLeadType: (state, action) => {
      state.leadType = action.payload;
    },

    clearUserData: () => initialState,
  },
});

export const {
  addUserInfo,
  addUserName,
  addUpgradeLoanAmount,
  addRepeatCustomer,
  changeLoanApproved,
  addAccountId,
  addLeadType,
  clearUserData,
} = userSlice.actions;

export default userSlice.reducer;
