import React, { useEffect } from "react";
import { Formik } from "formik";
import styles from "./EnterMobile.module.scss";
import Loader from "../../components/Loader/Loader";
import { CustomButton, Header } from "../../components";
import { MdOutlineCall } from "react-icons/md";
import { entermobileNumberValidation } from "../../validations/auth.validation";
import ServicesAPI from "../../services/services";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearRegisterData } from "../../redux/slices/registerSlice";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function EnterMobile() {
  const { enterMobileNumber } = new ServicesAPI();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearRegisterData());
    handleRedirect();
  }, []);

  const handleRedirect = () => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/home");
    }
  };

  return (
    <div className={`wrapper ${styles.home}`}>
      <Formik
        initialValues={{ mobileNumber: "" }}
        validationSchema={entermobileNumberValidation}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);

          setTimeout(async () => {
            const res = await enterMobileNumber(values.mobileNumber);

            if (res && res.statusCode === 200) {
              navigate("/enter-otp", { state: { mobileNumber: values.mobileNumber } });
            } else {
              setFieldError("mobileNumber", res.message);
            }

            WebEngageTrackingEvent("OTP Initiated");

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <Header />

                <div className="mb-2">
                  <p className={styles.label}>Enter Mobile Number</p>
                  <p className={styles.sub__label}>Please enter your mobile number</p>
                </div>

                <div className="form-group">
                  <div className={styles.call__icon}>
                    <MdOutlineCall size={28} color="#9a9a9a" />
                  </div>
                  <input
                    type="tel"
                    className={styles.form__input}
                    placeholder="Enter your mobile number"
                    name="mobileNumber"
                    value={values.mobileNumber}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input != "000000000000" && /^\d*$/.test(input)) {
                        setFieldValue("mobileNumber", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue("mobileNumber", e.target.value.trim());
                    }}
                    autoComplete="off"
                    maxLength={10}
                  />

                  {touched.mobileNumber && errors.mobileNumber ? (
                    <div className="error-msg">{errors.mobileNumber}</div>
                  ) : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <CustomButton text="Get OTP" type="submit" />

                <div className={styles.footerBar}>
                  <p className={styles.title}>Products offered</p>

                  <ul>
                    <li>1. Personal loan</li>
                    <li>2. Loan Against property</li>
                  </ul>

                  <br />

                  <p className={styles.title}>RATES & CHARGES</p>
                  <ul className={styles.disc}>
                    <li>Minimum loan amount - Rs 5,000 /-</li>
                    <li>Maximum loan amount - Rs 2,00,000 /-</li>
                    <li>Tenure - 92 Days to 365 Days.</li>
                    <li>Processing Fee - @ 2% of loan amount*</li>
                    <li>No pre - Closure Charges</li>
                    <li>No Prepayment Charges.</li>
                    <li>APR (Annual Percentage Rate) - @ 15% to 31%</li>
                    <li>Cheque Bounce Charges - Rs. 1000/-</li>
                  </ul>

                  <br />

                  <p>
                    <strong>How We Serve You :</strong> Payment Example Offering tailored solutions to meet your unique
                    needs, our loan process is efficient, transparent, and designed to empower your financial journey.
                  </p>

                  <ul className={styles.disc}>
                    <li>Loan amount :- 100000</li>
                    <li>Tenure :- 12</li>
                    <li>Interest Rate Yearly :- 27%</li>
                    <li>APR :- 30.92%</li>
                    <li>Monthly EMI :- 9602</li>
                    <li>Total Interest :- 15221</li>
                    <li>Amount Deposited :- 98000</li>
                  </ul>
                </div>
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
