import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import MainHeader from "../../components/Header/MainHeader";
import SelectOption from "../../components/Select/SelectOption";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { addWorkExperience } from "../../redux/slices/registerSlice";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";

export default function WorkExperience() {
  const [loading, setLoading] = useState(false);

  const { workExperience } = useSelector((state) => state.register);
  const dispatch = useDispatch();

  const { incompleteDetailsUpdate } = new ServicesAPI();
  const { handleStep } = useResponse();

  const data = ["0 - 2 Years", "2 - 5 Years", "5 - 8 Years", "8 - 10 Years", "More than 10 years"];

  const handleIncompleteDetailsUpdateAPI = async (data) => {
    setLoading(true);
    const res = await incompleteDetailsUpdate(data);

    if (res) {
      dispatch(addWorkExperience(data.value));
      handleStep();

      WebEngageUserAttributes("Work Experience", data.value);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="wrapper">
        <MainHeader title="Employment Details" showProgress={true} percent={76.9} />
        <div className="mb-3">
          <p className="label">Work Experience</p>
        </div>

        {data.map((item, index) => (
          <div
            key={index}
            onClick={async () => {
              handleIncompleteDetailsUpdateAPI({
                key: "totalExperience",
                value: item,
              });
              WebEngageTrackingEvent("Work Experience Submitted", {
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }}
          >
            <SelectOption name={item} checked={workExperience} />
          </div>
        ))}

        <br />
      </div>

      {loading && <Loader />}
    </>
  );
}
