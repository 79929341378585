import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import ServicesAPI from "../../services/services";

import useResponse from "../../hooks/useResponse";

export default function DigilockerVerify() {
  const [loading, setLoading] = useState(true);

  const { handleStep } = useResponse();
  const { aadhaarVerificationWebhookDigilocker, aadhaarPANVerify } = new ServicesAPI();

  useEffect(() => {
    handleAadhaarVerificationDigilocker();
  }, []);

  const handleAadhaarVerificationDigilocker = async () => {
    const params = {};
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.forEach((value, key) => {
      params[key] = value;
    });

    const res = await aadhaarVerificationWebhookDigilocker(params);

    if (res) {
      const res = await aadhaarPANVerify();

      if (res && res.statusCode === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "Verified Successfully",
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 2000,
        });
        setTimeout(() => {
          handleStep();
        }, 2000);
      } else if (res.statusCode === 400) {
        Swal.fire({
          text: res.message,
          icon: "error",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = res.data.route;
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          text: res.message,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/dashboard";
          }
        });
      }
    }

    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }
  return <div></div>;
}
