import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { storeLeadID, storeToken } from "../../utils/storage";
import useResponse from "../../hooks/useResponse";
import { useDispatch, useSelector } from "react-redux";
import { addAccountId, addRepeatCustomer, changeLoanApproved, clearUserData } from "../../redux/slices/userSlice";

import { persistor } from "../../redux/store";
import { clearRegisterData } from "../../redux/slices/registerSlice";

export default function Login() {
  const { handleStep } = useResponse();

  const { isLoanApproved } = useSelector((state) => state.user);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // localStorage.clear();
    // persistor.pause();
    // persistor.purge();
    // persistor.flush();

    handleUserLogin();
  }, [location.search, isLoanApproved]);

  const handleUserLogin = () => {
    dispatch(changeLoanApproved(false));
    dispatch(addAccountId(""));
    dispatch(addRepeatCustomer(false));

    setTimeout(() => {
      const queryParams = new URLSearchParams(location.search);

      const token = queryParams.get("token");
      const leadID = queryParams.get("leadID");

      // console.log("isLoanApproved-----------", isLoanApproved);

      if (leadID) {
        storeLeadID(leadID);
      } else {
        storeLeadID("");
      }

      if (token) {
        storeToken(token);

        if (!isLoanApproved) {
          handleStep("");
        }
      }
    }, 1000);
  };

  return (
    <div>
      <Loader />
    </div>
  );
}
