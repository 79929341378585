import React from "react";
import styles from "./Documents.module.scss";
import { LuDownload } from "react-icons/lu";

export default function Documents(props) {
  return (
    <div className={styles.documents}>
      <div className={styles.list}>
        <div>Welcome Letter</div>
        <div className={styles.download}>
          <LuDownload size={25} color="#1f1f1f" />
        </div>
      </div>

      <div className={styles.list}>
        <div>Pre-closure Statement</div>
        <div className={styles.download}>
          <LuDownload size={25} color="#1f1f1f" />
        </div>
      </div>

      <div className={styles.list}>
        <div>Loan Agreement</div>
        <div className={styles.download}>
          <LuDownload size={25} color="#1f1f1f" />
        </div>
      </div>

      <div className={styles.list}>
        <div>Sanction Letter</div>
        <div className={styles.download}>
          <LuDownload size={25} color="#1f1f1f" />
        </div>
      </div>

      <div className={styles.list}>
        <div>Loan Account Statement</div>
        <div className={styles.download}>
          <LuDownload size={25} color="#1f1f1f" />
        </div>
      </div>

      <div className={styles.list}>
        <div>Amortization schedule</div>
        <div className={styles.download}>
          <LuDownload size={25} color="#1f1f1f" />
        </div>
      </div>
    </div>
  );
}
