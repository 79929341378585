import React from "react";
import "./SelectOption.scss";

export default function SelectOption({ name, checked }) {
  return (
    <div className={`select-option ${checked === name ? "checked" : ""}`}>
      {name}
    </div>
  );
}
