import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

// components
import MainHeader from "../../components/Header/MainHeader";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import { bankDetailsValidation } from "../../validations/user.validation";
import { addAccountId } from "../../redux/slices/userSlice";
import Swal from "sweetalert2";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";
// import { addAccountId } from "../../redux/slices/registerSlice";

export default function EnterBankDetails() {
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState(null);
  const [showAccountNo, setShowAccountNo] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  const { leadType, repeatCustomer } = useSelector((state) => state.user);

  console.log("leadType-----------", leadType);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { backScreen } = location.state || "";

  const { getBankDetailsAPI, addBankDetailsAPI, getBankNameWithIFSC } = new AfterOnboardAPI();
  const { handleStep } = useResponse();

  useEffect(() => {
    const fetchBankDetails = async () => {
      const res = await getBankDetailsAPI();
      if (res && res.statusCode === 200) {
        setBankData(res.data);
      }
    };

    if (leadType !== "Repeat Case") {
      fetchBankDetails();
    }
  }, [repeatCustomer]);

  const submitBankDetails = async (values) => {
    setOpenDialog(false);
    setLoading(true);
    const res = await addBankDetailsAPI(values);
    if (res && res.statusCode === 200) {
      dispatch(addAccountId(res.data.accountId));

      if (backScreen) {
        window.location.href = backScreen;
      } else {
        handleStep(res.data.accountId);
      }
    }
    setLoading(false);
  };

  const initialValues = {
    accountHolderName: bankData?.name || "",
    accountNo: bankData?.accountNumber || "",
    confirmedAccountNo: bankData?.accountNumber || "",
    ifsc: bankData?.ifsc || "",
    bankName: bankData?.bankName || "",
  };

  const verifyAccountNumber = (number) => {
    if (bankData?.accountNumber) {
      const accountDigit = bankData.accountNumber.slice(-4);
      const valueDigit = number?.slice(-4);
      return valueDigit === accountDigit;
    }

    return false;
  };

  return (
    <div className="wrapper auto-height">
      <Formik
        initialValues={initialValues}
        validationSchema={bankDetailsValidation}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          const verifyAccount = verifyAccountNumber(values.accountNo);

          if (bankData?.accountNumber && !verifyAccount) {
            Swal.fire({
              icon: "error",
              text: `Enter account number ending with ${bankData.accountNumber.slice(-4)}`,
              timer: 3000,
            });
            setSubmitting(false);
            return;
          }

          setSubmitting(true);

          setTimeout(async () => {
            setOpenDialog(true);
            setSubmitting(false);

            WebEngageTrackingEvent("Bank Details Submitted", {
              "Bank Name": values.bankName,
              customer_type: localStorage.getItem("customerType"),
              app_version: localStorage.getItem("appVersion"),
            });
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%", minHeight: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <MainHeader title="Bank Details" backPageName={backScreen} />
                <p className="label">Enter your Bank Details</p>
                <p className="sub-label mb-3" style={{ marginTop: -5 }}>
                  Please Enter your Bank Details for Disbursal
                </p>

                <div className="form-group">
                  <label className="label-sm">Account Holder's Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Account Holder's Name"
                    name="accountHolderName"
                    value={values.accountHolderName || ""}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("accountHolderName", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.accountHolderName && errors.accountHolderName ? (
                    <div className="error-msg">{errors.accountHolderName}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="label-sm">Account Number</label>
                  <input
                    type={`${showAccountNo ? "password" : "tel"}`}
                    className="form-control"
                    placeholder="Enter Account Number"
                    name="accountNo"
                    value={values.accountNo || ""}
                    onChange={(e) => {
                      // const input = e.target.value;
                      // if (/^\d*$/.test(input)) {
                      //   setFieldValue("accountNo", e.target.value);
                      // }

                      setFieldValue("accountNo", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("accountNo", e.target.value.trim());
                    }}
                    autoComplete="off"
                    maxLength={18}
                  />

                  {touched.accountNo && errors.accountNo ? <div className="error-msg">{errors.accountNo}</div> : null}

                  <div style={{ position: "absolute", top: 48, right: 10 }}>
                    {showAccountNo ? (
                      <IoEyeSharp size={20} color="#7a7a7a" onClick={() => setShowAccountNo(!showAccountNo)} />
                    ) : (
                      <IoEyeOffSharp size={20} color="#7a7a7a" onClick={() => setShowAccountNo(!showAccountNo)} />
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="tel"
                    className="form-control"
                    placeholder="Re-enter Account Number"
                    name="confirmedAccountNo"
                    value={values.confirmedAccountNo || ""}
                    onChange={(e) => {
                      // const input = e.target.value;
                      // if (/^\d*$/.test(input)) {
                      //   setFieldValue("confirmedAccountNo", e.target.value);
                      // }
                      setFieldValue("confirmedAccountNo", e.target.value);
                    }}
                    onBlur={(e) => {
                      setFieldValue("confirmedAccountNo", e.target.value.trim());
                    }}
                    autoComplete="off"
                    maxLength={18}
                  />

                  {touched.confirmedAccountNo && errors.confirmedAccountNo ? (
                    <div className="error-msg">{errors.confirmedAccountNo}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label className="label-sm">IFSC Code</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter IFSC Code"
                    name="ifsc"
                    value={values.ifsc || ""}
                    onChange={async (e) => {
                      setFieldValue("ifsc", e.target.value);

                      if (e.target.value.length === 11) {
                        const res = await getBankNameWithIFSC(e.target.value);
                        if (res.statusCode === 200) {
                          setFieldValue("bankName", res?.data?.banks[0]?.BANK);
                        }
                      } else {
                        setFieldValue("bankName", "");
                      }
                    }}
                    onBlur={async (e) => {
                      setFieldValue("ifsc", e.target.value.trim());
                      if (e.target.value.length === 11) {
                        const res = await getBankNameWithIFSC(e.target.value);
                        if (res.statusCode === 200) {
                          setFieldValue("bankName", res?.data?.banks[0]?.BANK);
                        }
                      } else {
                        setFieldValue("bankName", "");
                      }
                    }}
                    autoComplete="off"
                    maxLength={11}
                    style={{ textTransform: "uppercase" }}
                  />

                  {touched.ifsc && errors.ifsc ? <div className="error-msg">{errors.ifsc}</div> : null}
                </div>

                <div className="form-group">
                  <label className="label-sm">Bank Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your Bank Name"
                    name="bankName"
                    value={values.bankName || ""}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("bankName", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.bankName && errors.bankName ? <div className="error-msg">{errors.bankName}</div> : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />
                <CustomButton text="Next" type="submit" />
              </div>
            </div>

            {loading && <Loader />}

            <Dialog
              open={openDialog}
              fullWidth
              onClose={() => setOpenDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Bank Details!"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">Are these information correct?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>NO</Button>
                <Button onClick={() => submitBankDetails(values)}>YES</Button>
              </DialogActions>
            </Dialog>
          </form>
        )}
      </Formik>
    </div>
  );
}
