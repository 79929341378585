import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useGoogleTag = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.gtag) {
      window.gtag("config", "AW-10905280606", {
        page_path: location.pathname,
      });
    }
  }, [location]);
};

export default useGoogleTag;
