import React, { useState } from "react";
import "./Modal.scss";
import Drawer from "@mui/material/Drawer";
import CustomButton from "../Button/CustomButton";
import { useTheme } from "@mui/material/styles";
import { getEnvironment } from "../../utils/environment";

export default function ConfirmBankModal(props) {
  const { openPanModal, setOpenPanModal, handleConfirmBankAccount } = props;
  const [checked, setChecked] = useState(false);

  const { bankDetails } = openPanModal;

  const theme = useTheme();

  const { PRODUCT } = getEnvironment();

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={openPanModal["bottom"]}
        onClose={() => setOpenPanModal({ ...openPanModal, bottom: false })}
        sx={{
          "& .MuiDrawer-paper": {
            maxWidth: 480,
            width: "100%",
            margin: "0 auto",
            left: "0",
            [theme.breakpoints.up("sm")]: {
              left: -15,
            },
          },
        }}
      >
        <div className="confirm-bank-modal">
          <h3>Your money will be transferred to the following bank account</h3>
          <p>Once the money is transferred to the below account, the transaction cannot be reversed.</p>

          <div className="bank-details">
            <div className="bank">
              {bankDetails?.bankLogo ? (
                <img src={bankDetails.bankIcon} width="50" style={{ width: 100 }} alt="" />
              ) : (
                <img src="/images/bank.png" width="50" alt="" />
              )}

              <p>
                <strong>Bank Name</strong>
              </p>
            </div>
            <div className="account-number">
              <p>Account Number</p>
              <p>{bankDetails?.accountNo}</p>
            </div>
          </div>

          <p>
            {PRODUCT === "ramfincorp_api" ? "Ram Fincorp" : "Kamakshi Money"} will not be responsible in case you
            provide incorrect information
          </p>

          <div className="term-condition">
            <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
            <p>
              I confirm to hold the bank account mentioned above, and take full responsibility for the correctness of
              all related information.
            </p>
          </div>

          <CustomButton text="Continue" onClick={handleConfirmBankAccount} disabled={!checked} />
        </div>
      </Drawer>
    </div>
  );
}
