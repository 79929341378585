import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";

export default function Dashboard() {
  useEffect(() => {
    handleRedirect();
  }, []);

  const handleRedirect = () => {
    setTimeout(() => {
      window.location.href = "/home";
    }, 3 * 1000);
  };

  return (
    <div>
      <Loader />
      <img src="/images/dashboard.svg" style={{ opacity: 0 }} alt="" />
    </div>
  );
}
