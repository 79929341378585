import React from "react";
import "./InfoMessage.scss";

export default function InfoMessage({ message }) {
  return (
    <div className="info-message">
      <img src="/images/i.svg" alt="icons" className="image" />
      <p className="text">{message}</p>
    </div>
  );
}
