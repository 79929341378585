import React, { useRef, useState } from "react";
import styles from "./Selfie.module.scss";
import Webcam from "react-webcam";
// import Swal from "sweetalert2";

import useResponse from "../../hooks/useResponse";
import Loader from "../../components/Loader/Loader";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import { Checkbox } from "@mui/material";
import { useSelector } from "react-redux";
import { WebEngageTrackingEvent } from "../../lib/webengage";
import CustomButton from "../../components/Button/CustomButton";
import { getEnvironment } from "../../utils/environment";

const label = { inputProps: { "aria-label": "Checkbox" } };

export default function Selfie() {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [checkTerm, setCheckTerm] = useState(false);

  const { userName } = useSelector((state) => state.user);

  const { handleStep } = useResponse();

  const webcamRef = useRef("");

  const { PRODUCT } = getEnvironment();

  const { selfieVerificationAPI } = new AfterOnboardAPI();

  const videoConstraints = {
    facingMode: "user",
    width: 220,
    height: 200,
  };

  function base64ToBlob(base64, contentType = "image/jpeg") {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const handleSubmit = async () => {
    setLoading(true);
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);

    const blob = base64ToBlob(imageSrc);

    const res = await selfieVerificationAPI(blob);

    // console.log("res----------", res);

    if (res) {
      handleStep();
      WebEngageTrackingEvent("Selfie Submitted", {
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
    } else {
      setLoading(false);
      setImage("");
    }
  };

  return (
    <>
      <div className="wrapper auto-height">
        <div className="vertical-column-layout">
          <div className="vertical-column-top">
            <p className="label mt-5 mb-3">Selfie verification</p>

            <div className={styles.webcamContainer}>
              {image === "" ? (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  className={styles.webcamCircle}
                />
              ) : (
                <img src={image} className={styles.webcamCircle} alt="" />
              )}
            </div>
            <p className={`${styles.subLabel} my-2`}>Please keep your face in center</p>
          </div>

          <div className="vertical-column-bottom">
            <div className={styles.accept}>
              <div>
                <Checkbox
                  {...label}
                  checked={checkTerm}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  onChange={(e) => setCheckTerm(e.target.checked)}
                />
              </div>
              <div>
                I'm {userName}, I'm Taking a loan from {PRODUCT === "ramfincorp_api" ? "Ram Fincorp" : "Kamakshi Money"}{" "}
                and I will repay on due date without fail.
              </div>
            </div>

            <DataSafetyMessage />

            <CustomButton
              text="Click Selfie"
              disabled={!checkTerm}
              onClick={() => {
                handleSubmit();
                WebEngageTrackingEvent("Selfie Initiated", {
                  customer_type: localStorage.getItem("customerType"),
                  app_version: localStorage.getItem("appVersion"),
                });
              }}
            />
          </div>
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
}
