import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  panVerifyData: null,
  loanPurpose: "",
  loanAmount: "",
  gender: "",
  maritalStatus: "",
  highestEducation: "",
  employmentDetails: "",
  paymentMode: "",
  companyName: "",
  industry: "",
  designation: "",
  monthlyIncome: "",
  workExperience: "",
  salaryDate: "",
  yourResidence: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    addPanVerifyData: (state, action) => {
      state.panVerifyData = action.payload;
    },

    addLoanPurpose: (state, action) => {
      state.loanPurpose = action.payload;
    },

    addLoanAmount: (state, action) => {
      state.loanAmount = action.payload;
    },

    addGender: (state, action) => {
      state.gender = action.payload;
    },

    addMaritalStatus: (state, action) => {
      state.maritalStatus = action.payload;
    },

    addHighestEducation: (state, action) => {
      state.highestEducation = action.payload;
    },

    addEmploymentDetails: (state, action) => {
      state.employmentDetails = action.payload;
    },

    addPaymentMode: (state, action) => {
      state.paymentMode = action.payload;
    },

    addCompanyName: (state, action) => {
      state.companyName = action.payload;
    },

    addIndustry: (state, action) => {
      state.industry = action.payload;
    },

    addDesignation: (state, action) => {
      state.designation = action.payload;
    },

    addMonthlyIncome: (state, action) => {
      state.monthlyIncome = action.payload;
    },

    addWorkExperience: (state, action) => {
      state.workExperience = action.payload;
    },

    addSalaryDate: (state, action) => {
      state.salaryDate = action.payload;
    },

    addYourResidence: (state, action) => {
      state.yourResidence = action.payload;
    },

    clearRegisterData: () => initialState,
  },
});

export const {
  addPanVerifyData,
  addLoanPurpose,
  addLoanAmount,
  addGender,
  addMaritalStatus,
  addHighestEducation,
  addEmploymentDetails,
  addPaymentMode,
  addCompanyName,
  addIndustry,
  addDesignation,
  addMonthlyIncome,
  addWorkExperience,
  addSalaryDate,
  addYourResidence,
  clearRegisterData,
} = registerSlice.actions;

export default registerSlice.reducer;
